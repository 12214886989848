<template>
  <div id="index" ref="appRef">
   生产管理
  </div>
</template>

<script>


export default {
  mixins: [  ],
  data() {
    return {

    }
  },
  components: {

  },
  mounted() {

  },

  methods: {


  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';
</style>
